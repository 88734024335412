<template>
  <div class="headnav">
    <!-- 顶部 -->
    <div class="top_nav container">
        <p>一条龙服务，正版软件模板定制一网打尽～</p>
        <ul>
          <li v-if="!$store.state.user.userInfo.id">
            <a href="#/passlogin">请登录</a>
          </li>
          <li v-if="!$store.state.user.userInfo.id">
            <a href="#/register">免费注册</a>
          </li>
          <li class="username" v-if="$store.state.user.userInfo.id">
            <a href="javascript:void(0)">{{$store.state.user.userInfo.username}} <i class="iconfont icon-xiajiantou"></i></a>
            <div class="jumpuser">
              <a href="#/useritem">我的信息</a>
              <a href="#/usercon">联系方式</a>
              <a href="#/userorder">我的订单</a>
              <a href="javascript:void(0)" @click="quit">安全退出</a>
            </div>
          </li>
          <li v-if="$store.state.user.userInfo.id">
            <a href="#/shopcar">购物车 <em>1</em> 件</a>
          </li>
          <li>
            <a href="#/useragree">用户协议</a>
          </li>
          <li>
            <a href="#/comsuggest">隐私协议</a>
          </li>
          <li>
            <a href="#/merchant">商家后台</a>
          </li>
        </ul>
    </div>

    <!-- 搜索区域 -->
    <div class="search_nav ">
      <div class="search_auto container">
        <div class="left">
        <a href="javascript:void(0)"><img @click="$router.push('/index')" src="../assets/image/logo.png" alt=""></a>
      </div>
      <div class="right">
        <div class="search_content">
          <input type="text" placeholder="请输入关键字">
          <a href="javascript:void(0)"><img src="../assets/image/search.png" alt=""> 搜索</a>
          <p><img src="../assets/image/fabu.png" alt=""> 免费发布</p>
          <ul>
            <li @click="change_search(index)" v-for="(item,index) in search_tab" :key="index"><a href="javascript:void(0)" :class="{search_active:check_search===index}">{{item.name}}</a></li>
          </ul>
        </div>
      </div>
      </div>
    </div>

    <!-- 导航区域 -->
    <div class="nav">
      <div class="nav_auto container">
        <div class="left">
          <img src="../assets/image/bread.png" alt="">
          <span>商品分类</span>
          <div class="hover_nav" :class="{showNavList: $route.path === '/index'}">
            <div class="con_nav">
              <div class="tit" v-for="(item1,index1) in classlist" :key="index1">
                  <p>{{item1[0].first}} ></p>
                  <ul>
                    <li @click="pointSort(item2.id,index1)" v-for="item2 in item1[0].three.slice(1,4)" :key="item2.id"><a href="javascript:void(0)">{{item2.three}}</a></li>
                  </ul>
              <div  class="hover_onenav" >
                 <div class="source">
                    <div v-for="(item3,index3) in item1" :key="index3"  class="source_list">
                      <p>{{item3.second}} ></p>
                      <ul>
                        <li @click="pointSort(item4.id,index1)" v-for="item4 in item3.three.slice(1,item3.three.length)" :key="item4.id"><a href="javascript:void(0)">{{item4.three}}</a></li>
                      </ul>
                    </div>
                 </div>
              </div>
              </div>
              <!-- <div class="tit">
                <p>软件市场 ></p>
                <ul>
                  <li><a href="javascript:void(0)">群发软件</a></li>
                  <li><a href="javascript:void(0)">搜索工具</a></li>
                  <li><a href="javascript:void(0)">SEO工具</a></li>
                </ul>
                <div class="hover_twonav">
                   <div class="source">
                    <div class="source_list">
                      <p>源码类型 ></p>
                      <ul>
                        <li v-for="(item,index) in 20" :key="index"><a href="javascript:void(0)">技术开发</a></li>
                      </ul>
                    </div>
                   </div>
                </div>
              </div>
              <div class="tit">
                <p>服务市场 ></p>
                <ul>
                  <li><a href="javascript:void(0)">技术开发</a></li>
                  <li><a href="javascript:void(0)">美工设计</a></li>
                  <li><a href="javascript:void(0)">软著申请</a></li>
                </ul>
                <div class="hover_threenav">
                  <div class="source">
                    <div class="source_list">
                      <p>应用端 ></p>
                      <ul>
                        <li v-for="(item,index) in 20" :key="index"><a href="javascript:void(0)">技术开发</a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tit">
                <p>SAAS市场 ></p>
                <ul>
                  <li><a href="javascript:void(0)">知识付费</a></li>
                  <li><a href="javascript:void(0)">美食餐饮</a></li>
                  <li><a href="javascript:void(0)">活动扩客</a></li>
                </ul>
              <div class="hover_fournav">
                 <div class="source">
                    <div class="source_list">
                      <p>源码类型 ></p>
                      <ul>
                        <li v-for="(item,index) in 20" :key="index"><a href="javascript:void(0)">技术开发</a></li>
                      </ul>
                    </div>
                    <div class="source_list">
                      <p>应用端 ></p>
                      <ul>
                        <li v-for="(item,index) in 20" :key="index"><a href="javascript:void(0)">技术开发</a></li>
                      </ul>
                    </div>
                 </div>
              </div>
              </div> -->
            </div>
          </div>
        </div>
        <div class="right">
        <ul>
          <li  @click="changNav(item.path)" v-for="(item,index) in conent_nav" :key="item.id"><a :class="{active_nav:check_nav===index}" href="javascript:void(0)">{{item.title}}</a></li>
        </ul>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data () {
    return {
      check_search: 0,
      check_nav: 999,
      search_tab: [
        { name: '商品' },
        { name: '店铺' },
        { name: '任务' }
      ],
      conent_nav: [
        { id: 1, title: '全部商品', path: '/allgoods' },
        { id: 2, title: '源码市场', path: '/source' },
        { id: 3, title: '软件市场', path: '/software' },
        { id: 4, title: '服务市场', path: '/serve' },
        { id: 5, title: 'SAAS账号', path: '/saas' },
        { id: 6, title: '店铺列表', path: '/shoplist' },
        { id: 7, title: '任务大厅', path: '/missionhall' }
      ],
      classlist: []
    }
  },
  async  created () {
    this.conent_nav.forEach((item, index) => {
      if (this.$route.path === item.path) {
        this.check_nav = index
      }
    })
    await this.getclasslist() // 获取目录的数据
  },
  methods: {
    change_search (index) {
      this.check_search = index
    },
    changNav (path) {
      this.$router.push(path)
    },
    // 获取目录的数据
    async  getclasslist () {
      // 先从vuex中获取，vuex有数据则直接用
      if (this.$store.state.commin.classList.context) {
        this.classlist = this.$store.state.commin.classList.context
      } else {
        await this.$store.dispatch('commin/getClassList')
        this.classlist = this.$store.state.commin.classList.context
        // console.log(this.classlist)
        // 把 全部 加进去 先判断有咩有全部字段
        this.classlist.forEach(item1 => {
          item1.forEach(item => {
            const temp = item.three.some(item1 => item1.three === '全部')
            if (!temp) {
              item.three.unshift({ three: '全部', id: 0 })
            }
          })
        })
      }
    },
    // 点击左边3个分类
    pointSort (id, index1) {
      // 跳源码市场
      if (index1 === 0) {
        this.$router.push({ path: '/source', query: { id } })
      }
      // 跳软件市场
      if (index1 === 1) {
        this.$router.push({ path: '/software', query: { id } })
      }
      // 跳服务市场
      if (index1 === 2) {
        this.$router.push({ path: '/serve', query: { id } })
      }
      // 跳SAAS账号
      if (index1 === 3) {
        this.$router.push({ path: '/saas', query: { id } })
      }
    },
    // 点击退出
    quit () {
      this.$confirm('此操作将退出登录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$store.dispatch('user/logout')
        this.$router.push('/passlogin')
        this.$message('退出成功', 'success')
      }).catch(() => {})
    }
  }
}
</script>

<style lang = "less" scoped>
/* 顶部 */
.top_nav{
    height: 30px;
  background: #F5F5F5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  p{
    font-size: 12px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #888888;
  }
  ul{
    height: 30px;
    display: flex;
    align-items: center;
    .username{
      height: 30px;
      line-height: 30px;
      &:hover{
        .jumpuser{
          display: block;
          z-index: 999;
        }
      }
     }
    li{
      position: relative;
      a{
        margin-right: 11px;
        display: inline-block;
        height: 14px;
        font-size: 12px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #888888;
        em{
          color: red;
        }
      }
      .jumpuser{
        position: absolute;
        background-color: #fff;
        box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
        min-width: 100px;
        left: 0;
        top: 30px;
        border-radius: 3px;
        z-index: 99;
        display: none;
        text-align: center;
        a{
          display: block;
          width: 100%;
          height: 35px;
          line-height: 35px;
          &:hover{
            color: @base-color;
            background-color: #eee;
          }
        }
      }
    }
  }

}

/* 搜索区域 */
.search_nav{
  background: #fff;
  .search_auto{
  height: 138px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .left{
    img{
      width: 175px;
      height: 44px;
    }
  }
  .right{
    .search_content{
    display: flex;
    position: relative;
    input{
      font-size: 14px;
      width: 583px;
      height: 44px;
      border: 3px solid @base-color;
      padding-left: 16px;
    }
    input::-webkit-input-placeholder {
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #888888;
    }
    a{
      display: inline-block;
      width: 89px;
      height: 49px;
      background: @base-color;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
      line-height:49px;
      text-align: center;
      img{
        width: 18px;
        height: 18px;
      }
    }
    p{
      cursor: pointer;
      margin-left: 18px;
      margin-right: 103px;
      width: 126px;
      height: 49px;
      background: @base-color;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
      text-align: center;
      line-height:49px;
      img{
        width: 17px;
        height: 17px;
      }
    }
    ul{
      display: flex;
      position: absolute;
      left: 0;
      top: -23.7px;
      li{
        a{
          display: inline-block;
          width: 45px;
          height: 19px;
          background: #E8F7F3;
          border-radius: 3px 3px 0 0;
          font-size: 10px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: @base-color;
          text-align: center;
          line-height:19px;
          margin-right: 5px;
        }
      }
    }
    }
  }
  }

}

/* 导航区域 */
.nav{
  background-color: #fff;
  border-bottom:2px solid @base-color;
  .nav_auto{
  position: relative;
  height: 40px;
  /* background-color: yellow; */
  display: flex;
  .left{
    cursor: pointer;
    margin-right: 16px;
    width: 230px;
    height: 40px;
    background: @base-color;
    display: flex;
    align-items: center;
    img{
      margin-right: 13px;
      margin-left: 40px;
      width: 16px;
      height: 20px;
    }
    span{
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
    }
    &:hover{
      .hover_nav{
        display: block;
      }
    }
  }
  .right{
    display: flex;
    align-items: center;
    ul{
      display: flex;
      li{
        padding:  0 16px;
        a{
          height: 40px;
          display: inline-block;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #4D4D4D;
          line-height: 40px;
          &:hover{
            color: @base-color;
            font-weight: 600;
          }
        }
      }
    }
  }
  .hover_nav{
    display: none;
    position: absolute;
    left: 0;
    top:41px;
    z-index: 999;
    height: 441px;
    width: 230px;
    background-color: #fff;
    .con_nav{
      .tit{
        height: 72px;
        padding: 8px 21px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        p{
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 400;
          color: #4D4D4D;
          margin-bottom: 5px;
        }
        ul{
          margin-top: 8px;
          display: flex;
          li{
            a{
              display: block;
              margin-right: 20px;
              font-size: 12px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #888888;
            &:hover{
              color: @base-color;
            }
            }
            &:last-child{
              a{
                margin-right: 0;
              }
            }
          }
        }
        &:hover{
          background-color: #f5f5f5;
        }
        /* hover第一个盒子的时候 */
        &:hover{
          .hover_onenav{
            display: block;
          }
        }
      }
    }
  }
  .hover_onenav{
      display: none;
      position: absolute;
      z-index: 999;
      height: 442px;
      width: 750px;
      background-color: #fff;
      left: 230px;
      top: 0px;
       border-left: 2px solid #f7f7f7;
      .source{
      padding: 19px 20px 10px 25px ;
      .source_list{
        display: flex;
        margin-bottom: 10px;
        p{
          font-size: 13px;
          width: 70px;
          text-align: right;
          padding-right: 15px;
        }
        ul{
          width: 621px;
          display: flex;
          flex-wrap: wrap;
          margin-top: 0;
          li{
            a{
               font-size: 10px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #888888;
                margin: 0 12px 12px 0;
                border-left: 1px solid #E9E9E9;
                padding-left: 10px;
                text-align: center;
            }
          }
        }
      }
    }
  }
  .hover_twonav{
      display: none;
      position: absolute;
      z-index: 999;
      height: 442px;
      width: 750px;
      background-color: #fff;
      left: 230px;
      top: 0px;
       border-left: 2px solid #f7f7f7;
      .source{
      padding: 19px 20px 10px 25px ;
      .source_list{
        display: flex;
        margin-bottom: 10px;
        p{
          font-size: 13px;
          width: 137px;
          text-align: right;
          padding-right: 15px;
        }
        ul{
          display: flex;
          flex-wrap: wrap;
          margin-top: 0;
          li{
            a{
               font-size: 10px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #888888;
                margin: 0 12px 12px 0;
                border-left: 1px solid #E9E9E9;
                padding-left: 10px;
            }
          }
        }
      }
    }
  }
  .hover_threenav{
      display: none;
      position: absolute;
      z-index: 999;
      height: 442px;
      width: 750px;
      background-color: #fff;
      left: 230px;
      top: 0px;
      border-left: 2px solid #f7f7f7;
    .source{
      padding: 19px 20px 10px 25px ;
      .source_list{
        display: flex;
        margin-bottom: 10px;
        p{
          font-size: 13px;
          width: 137px;
          text-align: right;
          padding-right: 15px;
        }
        ul{
          display: flex;
          flex-wrap: wrap;
          margin-top: 0;
          li{
            a{
               font-size: 10px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #888888;
                margin: 0 12px 12px 0;
                border-left: 1px solid #E9E9E9;
                padding-left: 10px;
            }
          }
        }
      }
    }
  }
  .hover_fournav{
      display: none;
      position: absolute;
      z-index: 999;
      height: 442px;
      width: 750px;
      background-color: #fff;
      left: 230px;
      top: 0px;
      border-left: 2px solid #f7f7f7;
     .source{
      padding: 19px 20px 10px 25px ;
      .source_list{
        display: flex;
        margin-bottom: 10px;
        p{
          font-size: 13px;
          width: 137px;
          text-align: right;
          padding-right: 15px;
        }
        ul{
          display: flex;
          flex-wrap: wrap;
          margin-top: 0;
          li{
            a{
               font-size: 10px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #888888;
                margin: 0 12px 12px 0;
                border-left: 1px solid #E9E9E9;
                padding-left: 10px;
            }
          }
        }
      }
    }
  }
  }
}

/* 搜索上的tab样式 */
 .search_active{
  background: @base-color !important;
  color: #FFFFFF !important;
}
.active_nav{
  color: @base-color !important;
  font-weight: 600 !important;
}

.showNavList{
  display: block !important;
}

</style>
