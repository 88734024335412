<template>
  <div>
    <div class="right">
        <div class="con_item">
            <el-tabs v-model="activeName" @tab-click="handleClick">
              <el-tab-pane @click.native="jump" label="我的信息" name="first">
                <div slot="label" @click="jump(1)">
                 我的信息
                </div>
                <p>用户ID  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{userInfo.id}}</p>
                <p>登录手机  &nbsp;&nbsp;{{userInfo.mobile}}</p>
                <el-form :model="form"  :rules="rules" ref="ruleForm"  label-width="100px" class="demo-ruleForm">
                <el-form-item label="昵称" prop="nickname">
                  <el-input  v-model="form.nickname"></el-input>
                </el-form-item>
                  <el-form-item label="性别" prop="sex">
                    <el-radio-group v-model="form.sex">
                      <el-radio :label="1" >男</el-radio>
                      <el-radio :label="2" >女</el-radio>
                    </el-radio-group>
                  </el-form-item>
               <el-form-item label="头像">
                 <el-upload
                    ref="uploadRef"
                    type="file"
                    class="avatar-uploader"
                    action=""
                    :show-file-list="false"
                    :auto-upload="false"
                    :on-change="handleAvatarSuccess">
                    <img v-if="imageUrl" :src="imageUrl" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    <!-- <div style="font-size:0.875rem;margin-left:20px;" slot="tip" class="el-upload__tip">只能上传jpg/png文件,且不超过500kb</div> -->
                  </el-upload>
                </el-form-item>
                  <el-form-item label="个人简介" >
                    <el-input placeholder="请输入个人简介" type="textarea" v-model="form.profile"></el-input>
                  </el-form-item>
                </el-form>
                <a href="javascript:void(0)" @click="subUserItem">更新信息</a>
              </el-tab-pane>
              <el-tab-pane label="修改密码" name="second">
                <div slot="label" @click="jump(2)">
                 修改密码
                </div>
               <div class="changpass">
                   <el-form :model="form_one"  :rules="rules" ref="ruleForm1"  label-width="100px" class="demo-ruleForm">
                  <el-form-item label="旧密码" prop="old_password">
                    <el-input type="password" v-model="form_one.old_password"></el-input>
                  </el-form-item>
                  <el-form-item label="新密码" prop="new_password">
                    <el-input type="password" v-model="form_one.new_password"></el-input>
                  </el-form-item>
                  <el-form-item label="确认新密码" prop="re_password">
                    <el-input type="password" v-model="form_one.re_password"></el-input>
                  </el-form-item>
                </el-form>
                <a href="javascript:void(0)" @click="subPassword">更新信息</a>
               </div>
              </el-tab-pane>
              <el-tab-pane label="联系方式" name="third">
                <div slot="label" @click="jump(3)">
                 联系方式
                </div>
                <div class="contact">
                  <el-form :model="form_two" label-width="100px" class="demo-ruleForm">
                    <el-form-item label="微信" prop="oldpass">
                      <el-input v-model="form_two.wechat"></el-input>
                    </el-form-item>
                    <el-form-item label="手机" prop="newpass">
                      <el-input v-model="form_two.mb"></el-input>
                    </el-form-item>
                    <el-form-item label="QQ" prop="newpassone">
                      <el-input v-model="form_two.qq"></el-input>
                    </el-form-item>
                    <el-form-item label="电话" prop="newpassone">
                      <el-input v-model="form_two.tel_phone"></el-input>
                    </el-form-item>
                  </el-form>
                  <a href="javascript:void(0)" @click="subContact">更新信息</a>
                </div>
              </el-tab-pane>
            </el-tabs>
        </div>

    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      activeName: '',
      form_two: {
        wechat: '',
        mb: '',
        qq: '',
        tel_phone: ''
      },
      oldform_two: {},
      form_one: {
        old_password: '',
        new_password: '',
        re_password: ''
      },
      form: {
        nickname: '',
        sex: 0,
        img: '',
        profile: ''
      },
      oldform: {},
      rules: {
        nickname: [
          {
            required: true,
            trigger: ['blur', 'change'],
            message: '用户名称不能为空'
          },
          { pattern: /^(?![0-9]+$)/, message: '用户名不能为纯数字', trigger: ['blur', 'change'] },
          { pattern: /^.{3,8}$/, message: '请输入3-8个字符长度', trigger: ['blur', 'change'] }
        ],
        sex: [
          { required: true, message: '请选择性别', trigger: ['blur', 'change'] }
        ],
        old_password: [
          { required: true, message: '旧密码不能为空', trigger: ['blur', 'change'] }
        ],
        new_password: [
          { required: true, trigger: ['blur', 'change'], message: '新密码不能为空' },
          { pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/, message: '密码由字母和数字组成且不少于8个字符', trigger: ['blur', 'change'] }
        ],
        re_password: [
          { required: true, trigger: ['blur', 'change'], message: '确认新密码不能为空' },
          { pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/, message: '密码由字母和数字组成且不少于8个字符', trigger: ['blur', 'change'] }
        ]
      },
      imageUrl: '',
      userInfo: this.$store.state.user.userInfo

    }
  },
  created () {
    console.log(this.userInfo)
    this.form.nickname = this.userInfo.nickname
    this.form.sex = this.userInfo.sex === 0 ? '' : this.userInfo.sex
    this.form.img = this.userInfo.img
    this.imageUrl = 'https://douchang.oss-cn-beijing.aliyuncs.com/media/' + this.userInfo.img
    this.form.profile = this.userInfo.profile
    this.oldform = { ...this.form }

    this.form_two.wechat = this.userInfo.wechat
    this.form_two.mb = this.userInfo.mb
    this.form_two.qq = this.userInfo.qq
    this.form_two.tel_phone = this.userInfo.tel_phone
    this.oldform_two = { ...this.form_two }
    if (this.$route.path === '/useritem') {
      this.activeName = 'first'
    }
    if (this.$route.path === '/userpass') {
      this.activeName = 'second'
    }
    if (this.$route.path === '/usercon') {
      this.activeName = 'third'
    }
  },
  methods: {
    handleClick (e) {
      // console.log(e, 888)
    },
    // 图片选择改变后 触发
    handleAvatarSuccess (file, fileList) {
      // 选择图片后的校验
      const isJPG = file.type === 'image/jpeg'
      const isPNG = file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 0.5
      if (!isJPG && !isPNG && !isLt2M) {
        this.$message('上传头像图片只能是 JPG/PNG 格式 且不超过500KB!')
      } else {
        this.imageUrl = URL.createObjectURL(file.raw)
        // 选择图片之后 把图片信息存起来，留到回显的时候用 (重新进入页面  this.picItem还是为空！！！！！)
        // console.log(file.raw)
        this.form.img = file.raw
      }
      return isJPG && isPNG && isLt2M
    },
    jump (e) {
      if (e === 1) {
        this.$router.push('/useritem')
      }
      if (e === 2) {
        this.$router.push('/userpass')
      }
      if (e === 3) {
        this.$router.push('/usercon')
      }
    },
    // 更新用户信息
    subUserItem () {
      if (JSON.stringify(this.form) === JSON.stringify(this.oldform)) return this.$message('请先修改个人信息', 'warning')
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          this.form.id = this.userInfo.id
          const formData = new FormData()
          Object.keys(this.form).forEach(item => formData.append(item, this.form[item]))
          await this.$store.dispatch('user/putuserinfo', formData)
          this.$message('更新成功', 'success')
          setTimeout(() => {
            this.$router.go(0)
          }, 1000)
        } else {
          this.$message('请输入必填项')
        }
      })
    },
    // 更新用户密码
    subPassword () {
      this.$refs.ruleForm1.validate(async (valid) => {
        if (valid) {
          this.form_one.id = this.userInfo.id
          // console.log(this.form_one)
          if (this.form_one.new_password !== this.form_one.re_password) return this.$message('两次密码输入不一致')
          await this.$store.dispatch('user/putuserpass', this.form_one)
          this.$message('更新密码成功,请重新登录', 'success')
          this.$store.dispatch('user/logout')
          this.$router.push('/passlogin')
        } else {
          this.$message('请输入必填项')
        }
      })
    },
    // 更新联系方式
    async  subContact () {
      if (JSON.stringify(this.form_two) === JSON.stringify(this.oldform_two)) return this.$message('请先修改信息', 'warning')
      this.form_two.id = this.userInfo.id
      await this.$store.dispatch('user/putcontact', this.form_two)
      this.$message('更新成功', 'success')
      setTimeout(() => {
        this.$router.go(0)
      }, 1000)
    }
  }
}
</script>

<style lang="less" scoped>
//用户信息设置
.user_item{
  display: flex;
  .right{
    width: 990px;
    background-color: #fff;
    .con_item{
      a{
        margin: 44px 0 0 80px;
        display: block;
        width: 120px;
        height: 43px;
        background: @base-color;
        border: 1px solid #DDDDDD;
        font-size: 15px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        text-align: center;
        line-height: 43px;
      }
      padding: 26px 13px ;
      /deep/.el-tabs__header{
        padding: 0px 20px;
      }
      /deep/.el-tabs__content{
        padding: 0px 40px;
      }
      p{
        margin: 30px 0;
        font-size: 15px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #7A7A7A;
      }
      /deep/.el-input__inner{
        width: 320px;
        margin-left: -20px;
      }
      /deep/.el-form-item__label{
        padding: 0 40px 0 0;
      }
      /deep/.el-radio-group{
         margin-left: -20px;
      }
      /deep/.el-textarea__inner{
        width: 500px;
        height: 100px;
        margin-left: -20px;
        font-size: 15px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000;
      }
    }
    .changpass{
      padding-top: 20px;
      /deep/.el-form-item__label{
        padding: 0 20px 0 0;
      }
      /deep/.el-input__inner {
        margin-left: 0;
      }
      a{
        margin: 30px 0 0 100px;
      }
    }
    .contact{
      padding-top: 20px;
      /deep/.el-form-item__label{
        padding: 0 20px 0 0;
      }
      /deep/.el-input__inner {
        margin-left: 0;
      }
      a{
        margin: 30px 0 0 100px;
      }
    }
  }
}

 .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 70px;
    height: 70px;
    line-height: 70px;
    text-align: center;
    border: 1px solid @base-color !important;
    margin-left: -20px;
  }
  .avatar {
    width: 70px;
    height: 70px;
    display: block;
    margin-left: -20px;
  }

/deep/.el-tabs__item.is-active{
  color: @base-color !important;
}
/deep/.el-tabs__active-bar{
  background-color: @base-color !important;
}
//修改 tabs 鼠标hover 时的颜色
.el-tabs {
  /deep/.el-tabs__item {
    &:hover {
      color: @base-color;
    }
  }
}
</style>
