<template>
<!--  商家后台左侧导航 -->
  <div class="merchant_leftnav">
    <div class="content">
      <dl>
        <dt>
          <div class="left">
            <img src="../assets/image/merdianpu.png" alt="">
            <p>店铺管理</p>
          </div>
          <i @click="show1 = !show1" class="iconfont icon-youjiantou"></i>
        </dt>
        <dd v-show="show1">
          <a href="#/shopinfor"  :class="{active:$route.path === '/shopinfor'}">店铺信息</a>
          <a href="javascript:void(0)">身份认证</a>
        </dd>
      </dl>
      <dl>
        <dt>
          <div class="left">
            <img src="../assets/image/merdianpu.png" alt="">
            <p>商品管理</p>
          </div>
          <i @click="show2 = !show2" class="iconfont icon-youjiantou"></i>
        </dt>
        <dd v-show="show2">
          <a href="#/mershoplist" :class="{active:$route.path === '/mershoplist' ||$route.path === '/addproduct' || $route.path === '/writeproitem' }">商品列表</a>
          <a href="javascript:void(0)" >收藏商品</a>
        </dd>
      </dl>
      <dl>
        <dt>
          <div class="left">
            <img src="../assets/image/merdianpu.png" alt="">
            <p>订单中心</p>
          </div>
          <i @click="show3 = !show3" class="iconfont icon-youjiantou"></i>
        </dt>
        <dd v-show="show3">
          <a href="javascript:void(0)" >订单列表</a>
        </dd>
      </dl>
    </div>
    <div class="merchant_bot">
      @ 2023 IT一条龙
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      show1: true,
      show2: true,
      show3: true
    }
  },
  created () {

  }
}
</script>

<style lang = "less" scoped>
.merchant_leftnav{
  position: fixed;
  top: 56px;
  left: 0;
  width: 200px;
  height: 100%;
  background-color: #fff;
  border: 1px solid #D9D8D8;
  border-top: 0;
  .content{
    padding: 5px 20px 0 0;
      dl{
        margin-top: 5px;
        cursor: pointer;
        dt{
        display: flex;
        align-items: center;
        margin-left: 24px;
        height: 40px;
        justify-content: space-between;
        .left{
          display: flex;
          align-items: center;
           img{
          width: 20px;
          height: 20px;
          object-fit: cover;
          margin-right: 10px;
         }
         p{
          font-size: 14px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 400;
          color: #000000;
         }
        }
      }
      dd{
        margin-left: 68px;
        line-height: 40px;
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 400;
        a{
          display: block;
          height: 40px;
        }
      }
      }
  }
  .merchant_bot{
    position: fixed;
    left: 0;
    bottom: 0;
    width: 200px;
    height: 50px;
    border: 1px solid #E4E3E3;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    color: #CCCCCC;
    text-align: center;
    line-height: 50px;
  }
}

/* //点击高亮 */
.active{
  color: @base-color;
}
</style>
