var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"top_nav container"},[_c('p',[_vm._v("一条龙服务，正版软件模板定制一网打尽～")]),_c('ul',[(!_vm.$store.state.user.userInfo.id)?_c('li',[_c('a',{attrs:{"href":"#/passlogin"}},[_vm._v("请登录")])]):_vm._e(),(!_vm.$store.state.user.userInfo.id)?_c('li',[_c('a',{attrs:{"href":"#/register"}},[_vm._v("免费注册")])]):_vm._e(),(_vm.$store.state.user.userInfo.id)?_c('li',{staticClass:"username"},[_c('a',{attrs:{"href":"javascript:void(0)"}},[_vm._v(_vm._s(_vm.$store.state.user.userInfo.username)+" "),_c('i',{staticClass:"iconfont icon-xiajiantou"})]),_c('div',{staticClass:"jumpuser"},[_c('a',{attrs:{"href":"#/useritem"}},[_vm._v("我的信息")]),_c('a',{attrs:{"href":"#/usercon"}},[_vm._v("联系方式")]),_c('a',{attrs:{"href":"#/userorder"}},[_vm._v("我的订单")]),_c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":_vm.quit}},[_vm._v("安全退出")])])]):_vm._e(),(_vm.$store.state.user.userInfo.id)?_c('li',[_vm._m(0)]):_vm._e(),_vm._m(1),_vm._m(2),_vm._m(3)])]),_c('div',{staticClass:"user_nav"},[_c('div',{staticClass:"site container"},[_c('a',{attrs:{"href":"javascript:void(0)"}},[_c('img',{attrs:{"src":require("../assets/image/logo1.png"),"alt":""},on:{"click":function($event){return _vm.$router.push('/index')}}})]),_c('ul',[_c('li',[_c('a',[_vm._v("用户中心")]),_c('em',{on:{"click":function($event){return _vm.$router.push('/index')}}},[_vm._v("返回平台首页")])]),_c('li',{on:{"click":function($event){return _vm.$router.push('/useritem')}}},[_vm._v("首页")]),_vm._m(4),_vm._m(5)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"#/shopcar"}},[_vm._v("购物车 "),_c('em',[_vm._v("1")]),_vm._v(" 件")])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"#/useragree"}},[_vm._v("用户协议")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"#/comsuggest"}},[_vm._v("隐私协议")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"#/merchant"}},[_vm._v("商家后台")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"javascript:void(0)"}},[_vm._v("账户设置 ^")]),_c('div',{staticClass:"downitem"},[_c('a',{attrs:{"href":"#/useritem"}},[_vm._v("我的信息")]),_c('a',{attrs:{"href":"#/userpass"}},[_vm._v("修改密码")]),_c('a',{attrs:{"href":"#/usercon"}},[_vm._v("联系方式")]),_c('a',{attrs:{"href":"#/personprise"}},[_vm._v("实名认证")]),_c('a',{attrs:{"href":"#/enterprise"}},[_vm._v("企业认证")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"javascript:void(0)"}},[_vm._v("交易与资产 ^")]),_c('div',{staticClass:"downitem"},[_c('a',{attrs:{"href":"#/userorder"}},[_vm._v("我的订单")]),_c('a',{attrs:{"href":"#/usermoney"}},[_vm._v("我的钱包")]),_c('a',{attrs:{"href":"#/usercollect"}},[_vm._v("收藏的商品")]),_c('a',{attrs:{"href":"#/userfollow"}},[_vm._v("收藏的店铺")])])])
}]

export { render, staticRenderFns }