
import request from '../utils/request'

// 获取全部的商品数据
export const GetAllProduct = (item) => request.get('/products/products/', { params: item })

// 获取筛选的商品数据
export const GetselProduct = (item) => request.get(`/products/products/?page=1&size=9999&${item}`)

// 获取商品详情的数据
export const GetProdetail = (id) => request.get(`/products/products/${id}`)
