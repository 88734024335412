<template>
  <div>
    <!-- 顶部 -->
    <div class="top_nav container">
        <p>一条龙服务，正版软件模板定制一网打尽～</p>
        <ul>
          <li v-if="!$store.state.user.userInfo.id">
            <a href="#/passlogin">请登录</a>
          </li>
          <li v-if="!$store.state.user.userInfo.id">
            <a href="#/register">免费注册</a>
          </li>
          <li class="username" v-if="$store.state.user.userInfo.id">
            <a href="javascript:void(0)">{{$store.state.user.userInfo.username}} <i class="iconfont icon-xiajiantou"></i></a>
            <div class="jumpuser">
              <a href="#/useritem">我的信息</a>
              <a href="#/usercon">联系方式</a>
              <a href="#/userorder">我的订单</a>
              <a href="javascript:void(0)" @click="quit">安全退出</a>
            </div>
          </li>
          <li v-if="$store.state.user.userInfo.id">
            <a href="#/shopcar">购物车 <em>1</em> 件</a>
          </li>
          <li>
            <a href="#/useragree">用户协议</a>
          </li>
          <li>
            <a href="#/comsuggest">隐私协议</a>
          </li>
          <li>
            <a href="#/merchant">商家后台</a>
          </li>
        </ul>
    </div>
    <!-- 顶部 -->

   <!-- 用户导航 -->
    <div class="user_nav">
      <div class="site container">
         <a href="javascript:void(0)"><img @click="$router.push('/index')" src="../assets/image/logo1.png" alt=""></a>
         <ul>
          <li><a>用户中心</a><em @click="$router.push('/index')">返回平台首页</em></li>
          <li  @click="$router.push('/useritem')">首页</li>
          <li>
            <a href="javascript:void(0)" >账户设置 ^</a>
            <div class="downitem">
              <a href="#/useritem">我的信息</a>
              <a href="#/userpass">修改密码</a>
              <a href="#/usercon">联系方式</a>
              <a href="#/personprise">实名认证</a>
              <a href="#/enterprise">企业认证</a>
            </div>
          </li>
          <li>
            <a href="javascript:void(0)" >交易与资产 ^</a>
            <div class="downitem">
              <a href="#/userorder">我的订单</a>
              <a href="#/usermoney">我的钱包</a>
              <a href="#/usercollect">收藏的商品</a>
              <a href="#/userfollow">收藏的店铺</a>
            </div>
          </li>
         </ul>
      </div>
    </div>
    <!-- 用户导航 -->
  </div>
</template>

<script>
export default {
  methods: {
    // 点击退出
    quit () {
      this.$confirm('此操作将退出登录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$store.dispatch('user/logout')
        this.$router.push('/passlogin')
        this.$message('退出成功', 'success')
      }).catch(() => {})
    }
  }
}
</script>

<style lang ="less" scoped>
/* 顶部 */
.top_nav{
    height: 30px;
  background: #F5F5F5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  p{
    font-size: 12px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #888888;
  }
  ul{
    height: 30px;
    display: flex;
    align-items: center;
    .username{
      height: 30px;
      line-height: 30px;
      &:hover{
        .jumpuser{
          display: block;
        }
      }
     }
    li{
      position: relative;
      a{
        margin-right: 11px;
        display: inline-block;
        height: 14px;
        font-size: 12px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #888888;
        em{
          color: red;
        }
      }
      .jumpuser{
        position: absolute;
        background-color: #fff;
        box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
        min-width: 100px;
        left: 0;
        top: 30px;
        border-radius: 3px;
        z-index: 99;
        display: none;
        text-align: center;
        a{
          display: block;
          width: 100%;
          height: 35px;
          line-height: 35px;
          &:hover{
            color: @base-color;
            background-color: #eee;
          }
        }
      }
    }
  }

}

// 用户导航
.user_nav{
  height: 80px;
  background: @base-color;
  margin-bottom: 10px;
  .site{
    display: flex;
    padding: 20px 0;
    img{
      width: 150px;
      height: 40px;
      margin-right: 50px;
    }
    ul{
      display: flex;
      height: 60px;
      li{
        cursor: pointer;
        margin: 0 30px;
        text-align: center;
        line-height: 40px;
        font-size: 13px;
        font-family: PingFangSC-Semibold, PingFang SC;
        color: #FFFFFF;
        &:first-child{
        line-height: 1;
          a{
            display: block;
            font-size: 15px;
            font-family: PingFangSC-Semibold, PingFang SC;
            color: #FFFFFF;
          }
          em{
            display: block;
            margin-top: 10px;
            width: 80px;
            height: 15px;
            border: 1px solid #CCCCCC;
            border-radius: 10px;
            line-height: 15px;
            font-size: 12px !important;
          }
        }
        &:nth-child(3){
          position: relative;
          a{
            color: #fff;
          }
          &:hover{
            .downitem{
              display: block;
            }
          }
          .downitem{
            display: none;
              position: absolute;
              top: 34px;
              left: 0;
              background-color: #fff;
              border: 1px solid #ddd;
              border-top: 0;
              font-size: 12px;
              z-index: 999;
              a{
                display: block;
                width: 88px;
                line-height: 30px;
                color: #666;
                text-align: center;
                &:hover{
                  color: @base-color;
                  background: #eeeeee;
                }
              }
          }
        }
        &:nth-child(4){
          position: relative;
          a{
            color: #fff;
          }
          &:hover{
            .downitem{
              display: block;
            }
          }
          .downitem{
            display: none;
              position: absolute;
              top: 34px;
              left: 0;
              background-color: #fff;
              border: 1px solid #ddd;
              border-top: 0;
              font-size: 12px;
              z-index: 999;
              a{
                display: block;
                width: 88px;
                line-height: 30px;
                color: #666;
                text-align: center;
                &:hover{
                  color: @base-color;
                  background: #eeeeee;
                }
              }
          }
        }
      }
    }
  }
}

@media screen and (max-width:1200px){
  .user_nav{
    width: 1200px !important;
  }
}
</style>
