var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"content container"},[_c('div',{staticClass:"content_bot"},[_c('ul',_vm._l((_vm.item.slice(0,10)),function(item1,index1){return _c('li',{key:index1,on:{"click":function($event){return _vm.$router.push({path:'/goodsdetail',query:{id:item1.id}})}}},[_c('img',{attrs:{"src":item1.img,"alt":""}}),_c('div',{staticClass:"goods_item"},[_c('p',[_vm._v(_vm._s(item1.price))]),_c('h5',[_vm._v(_vm._s(item1.title))]),_c('div',{staticClass:"goods_start"},[_c('div',{staticClass:"start_left"},[_c('img',{attrs:{"src":item1.logo,"alt":""}}),_c('span',[_vm._v(_vm._s(item1.name))])]),_c('div',{staticClass:"shop_style"},[_c('dl',[(JSON.parse(item1.marks.biao))?_c('dt',[_vm._m(0,true)]):_vm._e(),(JSON.parse(item1.marks.zheng))?_c('dt',[_vm._m(1,true)]):_vm._e(),(JSON.parse(item1.marks.zi))?_c('dt',[_vm._m(2,true)]):_vm._e(),(JSON.parse(item1.marks.shou))?_c('dt',[_vm._m(3,true)]):_vm._e(),(JSON.parse(item1.marks.an))?_c('dt',[_vm._m(4,true)]):_vm._e(),(JSON.parse(item1.marks.bao))?_c('dt',[_vm._m(5,true)]):_vm._e(),(JSON.parse(item1.marks.qi))?_c('dt',[_vm._m(6,true)]):_vm._e()])])])])])}),0)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"javascript:void(0)"}},[_c('img',{attrs:{"src":require("../assets/image/shop_tab6.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"javascript:void(0)"}},[_c('img',{attrs:{"src":require("../assets/image/shop_tab5.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"javascript:void(0)"}},[_c('img',{attrs:{"src":require("../assets/image/shop_tab4.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"javascript:void(0)"}},[_c('img',{attrs:{"src":require("../assets/image/shop_tab3.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"javascript:void(0)"}},[_c('img',{attrs:{"src":require("../assets/image/shop_tab2.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"javascript:void(0)"}},[_c('img',{attrs:{"src":require("../assets/image/shop_tab1.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"javascript:void(0)"}},[_c('img',{attrs:{"src":require("../assets/image/shop_tab.png"),"alt":""}})])
}]

export { render, staticRenderFns }