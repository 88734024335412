import request from '../utils/request'
// 用户登录
import qs from 'qs'

// 获取验证码
export const GetYan = (data) => request.get('/user/sms/', { params: data })

// 用户注册的接口
export const UserRegister = (data) => request.post('/user/user/', data)

// 获取用户
export const GetUser = (data) => request.get('/user/user/', { params: data })

// 用户登录 密码登录
export const UserLogin = (data) => request({
  method: 'post',
  url: '/user/userLogin/',
  data: qs.stringify(data),
  headers: {
    Accept: 'application/json'
  }
})

// 获取用户信息
export const GetUserInfo = () => request.get('/user/getInfo/')

// 手机验证码登录
// export const PhoneLogin = (data) => request.post('/user/mobileLogin/', data)
export const PhoneLogin = (data) => request({
  method: 'post',
  url: '/user/mobileLogin/',
  data: qs.stringify(data),
  headers: {
    Accept: 'application/json'
  }
})

// 更新用户信息

export const PutUserInfo = (data) => request({
  method: 'put',
  url: `/user/putinfo/${data.get('id')}/`,
  data: data,
  headers: { 'Content-Type': 'multipart/form-data' }
})

// 更新用户密码
export const PutUserPass = (data) => request.put(`/user/putpassword/${data.id}/`, data)

// 更新联系方式
export const PutContact = (data) => request.put(`/user/putcontact/${data.id}/`, data)
