// 登录拦截
import router from '@/router'
import store from '@/store'
// import { Message } from 'element-ui'
// 白名单页面
// const whiteList = []

// 前置导航守卫
router.beforeEach(async (to, from, next) => {
  const token = store.state.user.token // 一定放在导航守卫内获取
  // 1. 先判断是否有token, 如果有token,还要去登录页面, 强制去首页
  if (token) {
    if (to.path === '/passlogin' || to.path === '/phonelogin' || to.path === '/register') {
      next('/index') // 被next强制拦截的页面是不会进入到后置导航守卫中的
    } else {
      // 在有token后 进入页面前获取用户信息
      if (!store.state.user.userInfo.id) {
        // 登录成功后获取用户信息
        await store.dispatch('user/getuserinfo')
      }
      // 有token 想去哪就去哪
      next()
    }
  } else {
    next()
  }
})
