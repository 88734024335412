import { render, staticRenderFns } from "./NoItem.vue?vue&type=template&id=32bda227&scoped=true"
import script from "./NoItem.vue?vue&type=script&lang=js"
export * from "./NoItem.vue?vue&type=script&lang=js"
import style0 from "./NoItem.vue?vue&type=style&index=0&id=32bda227&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32bda227",
  null
  
)

export default component.exports