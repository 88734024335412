import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  // tab栏切换页面
  {
    path: '/',
    redirect: '/index'
  },
  {
    path: '/index',
    component: () => import('@/views/IndexPage')
  },
  {
    path: '/allgoods',
    component: () => import('@/views/AllGoodsPage')
  },
  {
    path: '/source',
    component: () => import('@/views/SourceCodePage')
  },
  {
    path: '/saas',
    component: () => import('@/views/SaasPage')
  },
  {
    path: '/software',
    component: () => import('@/views/SoftwarePage')
  },
  {
    path: '/serve',
    component: () => import('@/views/ServePage')
  },
  {
    path: '/shoplist',
    component: () => import('@/views/ShoplistPage')
  },
  {
    path: '/missionhall',
    component: () => import('@/views/MissionhallPage')
  },

  // 详情页面
  {
    path: '/goodsdetail',
    component: () => import('@/views/details/GoodsDetail')
  },
  {
    path: '/shop',
    component: () => import('@/views/details/ShopPage')
  },
  {
    path: '/missiondetail',
    component: () => import('@/views/details/MissionDetail')
  },

  // 登录注册页面
  {
    path: '/passlogin',
    component: () => import('@/views/login/PassLoginPage')
  },
  {
    path: '/phonelogin',
    component: () => import('@/views/login/PhoneLoginPage')
  },
  {
    path: '/register',
    component: () => import('@/views/register/RegisterPage')
  },

  // 用户信息相关页面
  {
    path: '/useritem',
    component: () => import('@/views/user/UserItemPage')
  },
  {
    path: '/userpass',
    component: () => import('@/views/user/UserPassPage')
  },
  {
    path: '/usercon',
    component: () => import('@/views/user/UserConPage')
  },
  {
    path: '/userorder',
    component: () => import('@/views/user/UserOrderPage')
  },
  {
    path: '/orderdetail',
    component: () => import('@/views/user/detail/OrderDetail')
  },
  {
    path: '/usermoney',
    component: () => import('@/views/user/UserMoneyPage')
  },
  {
    path: '/cashmoney',
    component: () => import('@/views/user/detail/CashMoney')
  },
  {
    path: '/topupmoney',
    component: () => import('@/views/user/detail/TopupMoney')
  },
  {
    path: '/enterprise',
    component: () => import('@/views/user/detail/EnterPrise')
  },
  {
    path: '/personprise',
    component: () => import('@/views/user/detail/PersonPrise')
  },
  {
    path: '/usercollect',
    component: () => import('@/views/user/UserCollectPage')
  },
  {
    path: '/userfollow',
    component: () => import('@/views/user/UserFollowPage')
  },
  {
    path: '/userpublish',
    component: () => import('@/views/user/UserPublishPage')
  },
  {
    path: '/userjoin',
    component: () => import('@/views/user/UserJoinPage')
  },
  {
    path: '/releasetask',
    component: () => import('@/views/user/detail/ReleaseTask')
  },

  // 服务中心页面 || 隐私协议页面
  {
    path: '/about',
    component: () => import('@/views/ServeCenter/serve/AboutPage')
  },
  {
    path: '/contactus',
    component: () => import('@/views/ServeCenter/serve/ContactUsPage')
  },
  {
    path: '/useragree',
    component: () => import('@/views/ServeCenter/serve/UserAgreePage')
  },
  {
    path: '/privacyagree',
    component: () => import('@/views/ServeCenter/serve/PrivacyAgreePage')
  },
  {
    path: '/comsuggest',
    component: () => import('@/views/ServeCenter/serve/ComSuggestPage')
  },
  {
    path: '/fraud',
    component: () => import('@/views/ServeCenter/buyguide/FraudPage')
  },
  {
    path: '/delivery',
    component: () => import('@/views/ServeCenter/buyguide/DeliverywayPage')
  },
  {
    path: '/payway',
    component: () => import('@/views/ServeCenter/buyguide/PaywayPage')
  },
  {
    path: '/howbuy',
    component: () => import('@/views/ServeCenter/buyguide/HowbuyPage')
  },
  {
    path: '/howregister',
    component: () => import('@/views/ServeCenter/buyguide/HowregisterPage')
  },
  {
    path: '/liberalshop',
    component: () => import('@/views/ServeCenter/merchant/LiberalShopPage')
  },
  {
    path: '/shopmoney',
    component: () => import('@/views/ServeCenter/merchant/ShopmoneyPage')
  },
  {
    path: '/pushpro',
    component: () => import('@/views/ServeCenter/merchant/PushproPage')
  },
  {
    path: '/proorder',
    component: () => import('@/views/ServeCenter/merchant/ProOrderPage')
  },
  {
    path: '/nocheat',
    component: () => import('@/views/ServeCenter/securebuy/NoCheatPage')
  },
  {
    path: '/prosecure',
    component: () => import('@/views/ServeCenter/securebuy/ProSecurePage')
  },
  {
    path: '/buyweek',
    component: () => import('@/views/ServeCenter/securebuy/BuyWeekPage')
  },
  {
    path: '/buysecure',
    component: () => import('@/views/ServeCenter/securebuy/BuySecurePage')
  },

  // 购物车订单相关页面
  {
    path: '/shopcar',
    component: () => import('@/views/shopcar/ShopCarPage')
  },
  {
    path: '/suborder',
    component: () => import('@/views/shopcar/SubOrder')
  },
  {
    path: '/checkstand',
    component: () => import('@/views/shopcar/CheckStand')
  },

  // 商家后台页面
  {
    path: '/merchant',
    component: () => import('@/views/merchant/MerchantPage')
  },
  {
    path: '/shopinfor',
    component: () => import('@/views/merchant/ShopInforPage')
  },
  {
    path: '/mershoplist',
    component: () => import('@/views/merchant/MerShopListPage')
  },
  {
    path: '/addproduct',
    component: () => import('@/views/merchant/another/AddProduct.vue')
  },
  {
    path: '/writeproitem',
    component: () => import('@/views/merchant/another/WriteProItem.vue')
  }
]

const router = new VueRouter({
  routes,
  // 路由切换页面的滚动行为
  scrollBehavior (to, from, savedPosition) {
    // if (savedPosition) {
    //   // 如果之前有保存滚动位置，则恢复到之前保存的位置
    //   return savedPosition
    // } else {
    // 否则，默认滚动到页面顶部
    return { x: 0, y: 0 }
    // }
  }
})

// 修复 重复渲染路由 报错
// 获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
// 修改原型对象中的push方法
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
export default router
