import { GetOneSort, GetArticle } from '@/api/article'
export default {
  namespaced: true,
  state: {
    onesort: [], // 文章一级分类
    article: [] // 文章标题和数据
  },
  mutations: {
    // 获取文章的一级分类
    setonesort (state, item) {
      state.onesort = item
    },
    // 获取文章标题和数据
    setarticle (state, item) {
      state.article = item
    }
  },
  actions: {
    // 获取文章的一级分类
    async  getonesort ({ commit }) {
      const res = await GetOneSort()
      // console.log(res.data)
      commit('setonesort', res.data)
    },
    // 获取文章的标题和数据
    async  getarticle ({ commit }) {
      const res = await GetArticle()
      // console.log(res.data)
      commit('setarticle', res.data)
    }
  }
}
