<template>
  <div>
    <div class="left">
         <dl>
          <dt><a href="javascript:void(0)">个人信息</a></dt>
          <dd><a href="#/useritem" :class="{active: $route.path==='/useritem'}">我的信息</a></dd>
          <dd><a href="#/userpass" :class="{active: $route.path==='/userpass'}">修改密码</a></dd>
          <dd><a href="#/usercon" :class="{active: $route.path==='/usercon'}">联系方式</a></dd>
         </dl>
         <dl>
          <dt><a href="javascript:void(0)">交易中心</a></dt>
          <dd><a href="#/userorder" :class="{active: $route.path==='/userorder'||$route.path==='/orderdetail'}">我的订单</a></dd>
         </dl>
         <dl>
          <dt><a href="javascript:void(0)">我的资产</a></dt>
          <dd><a href="#/usermoney" :class="{active: $route.path==='/usermoney' || $route.path==='/cashmoney' ||$route.path==='/topupmoney'}">我的钱包</a></dd>
         </dl>
         <dl>
          <dt><a href="javascript:void(0)">身份认证</a></dt>
          <dd><a href="#/personprise" :class="{active: $route.path ==='/personprise'}">实名认证</a></dd>
          <dd><a href="#/enterprise" :class="{active: $route.path ==='/enterprise'}">企业认证</a></dd>
         </dl>
         <dl>
          <dt><a href="javascript:void(0)">我的收藏</a></dt>
          <dd><a href="#/usercollect" :class="{active: $route.path ==='/usercollect'}">收藏商品</a></dd>
          <dd><a href="#/userfollow" :class="{active: $route.path ==='/userfollow'}">关注店铺</a></dd>
         </dl>
         <dl>
          <dt><a href="javascript:void(0)">任务大厅</a></dt>
          <dd><a href="#/userpublish" :class="{active: $route.path ==='/userpublish' || $route.path ==='/releasetask'}">我发布的任务</a></dd>
          <dd><a href="#/userjoin" :class="{active: $route.path ==='/userjoin'}">我参与的任务</a></dd>
         </dl>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
  .left{
    width: 200px;
    background-color: #fff;
    padding: 10px 0 10px 0;
    margin-right: 10px;
    dl{
      dt{
        a{
          padding-left: 24px;
          display: block;
          height: 40px;
          font-weight: 700;
          line-height: 40px;
          font-size: 15px;
        }
      }
      dd{
        position: relative;
        a{
          padding-left: 48px;
          display: block;
          height: 40px;
          line-height: 40px;
          font-size: 14px;
          &:hover{
            color: @base-color;
            background-color: #e5f1ee;
            &::before {
              position: absolute;
              content: '';
              width: 4px;
              height: 40px;
              background: @base-color;
              left: 0;
              top: 0;
            }
          }
        }
      }
    }
  }
  //左侧切换样式
.active{
  color: @base-color;
  background-color: #e5f1ee;
  &::before {
    position: absolute;
    content: '';
    width: 4px;
    height: 40px;
    background: @base-color;
    left: 0;
    top: 0;
}
}
</style>
