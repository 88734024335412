import { GetYan, UserRegister, GetUser, UserLogin, GetUserInfo, PhoneLogin, PutUserInfo, PutUserPass, PutContact } from '@/api/user'
import { setToken, getToken, removeToken } from '@/utils/cookie.js'
export default {
  namespaced: true,
  state: {
    token: getToken() || '',
    userInfo: {} // 用户信息
  },
  mutations: {
    // 存储token的mutation
    setToken (state, token) {
      // 将token存储到vuex中
      state.token = token
      // 将token存储到cookie中持久化
      setToken(token)
    },
    // 设置个人信息的mutation
    setUserInfo (state, info) {
      state.userInfo = info
    },
    // 移除token
    removeToken (state) {
      state.token = '' // vuex中清除
      removeToken() // 本地清除
    },
    // 移除个人信息
    removeUserInfo (state) {
      state.userInfo = {}
    }
  },
  actions: {
    // 获取验证码
    async GetYan ({ commit }, item) {
      const res = await GetYan(item)
      return res
    },
    // 用户注册的接口
    async userReg ({ commit }, item) {
      const res = await UserRegister(item)
      return res
    },
    // 获取用户
    async getUser ({ commit }, item) {
      const res = await GetUser(item)
      return res
    },
    // 账号密码登录
    async passLogin ({ commit }, item) {
      const res = await UserLogin(item)
      // console.log(res)
      commit('setToken', res.data.data.token)
    },
    // 手机验证码登录
    async phonelogin ({ commit }, item) {
      const res = await PhoneLogin(item)
      commit('setToken', res.data.data.token)
    },
    // 获取用户信息
    async getuserinfo ({ commit }) {
      const res = await GetUserInfo()
      // console.log(res)
      commit('setUserInfo', res.data.data)
    },
    // 更新用户信息
    async putuserinfo ({ commit }, item) {
      await PutUserInfo(item)
      // console.log(res)
    },
    // 更新用户密码
    async putuserpass ({ commit }, item) {
      // console.log(item, 99999)
      await PutUserPass(item)
    },
    // 更新联系方式
    async putcontact ({ commit }, item) {
      const res = await PutContact(item)
      console.log(res)
    },
    // 退出登录
    logout ({ commit }) {
      // 移除token
      commit('removeToken')
      // 移除用户信息
      commit('removeUserInfo')
    }
  }
}
