var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"left"},[_c('dl',[_vm._m(0),_c('dd',[_c('a',{class:{active: _vm.$route.path==='/useritem'},attrs:{"href":"#/useritem"}},[_vm._v("我的信息")])]),_c('dd',[_c('a',{class:{active: _vm.$route.path==='/userpass'},attrs:{"href":"#/userpass"}},[_vm._v("修改密码")])]),_c('dd',[_c('a',{class:{active: _vm.$route.path==='/usercon'},attrs:{"href":"#/usercon"}},[_vm._v("联系方式")])])]),_c('dl',[_vm._m(1),_c('dd',[_c('a',{class:{active: _vm.$route.path==='/userorder'||_vm.$route.path==='/orderdetail'},attrs:{"href":"#/userorder"}},[_vm._v("我的订单")])])]),_c('dl',[_vm._m(2),_c('dd',[_c('a',{class:{active: _vm.$route.path==='/usermoney' || _vm.$route.path==='/cashmoney' ||_vm.$route.path==='/topupmoney'},attrs:{"href":"#/usermoney"}},[_vm._v("我的钱包")])])]),_c('dl',[_vm._m(3),_c('dd',[_c('a',{class:{active: _vm.$route.path ==='/personprise'},attrs:{"href":"#/personprise"}},[_vm._v("实名认证")])]),_c('dd',[_c('a',{class:{active: _vm.$route.path ==='/enterprise'},attrs:{"href":"#/enterprise"}},[_vm._v("企业认证")])])]),_c('dl',[_vm._m(4),_c('dd',[_c('a',{class:{active: _vm.$route.path ==='/usercollect'},attrs:{"href":"#/usercollect"}},[_vm._v("收藏商品")])]),_c('dd',[_c('a',{class:{active: _vm.$route.path ==='/userfollow'},attrs:{"href":"#/userfollow"}},[_vm._v("关注店铺")])])]),_c('dl',[_vm._m(5),_c('dd',[_c('a',{class:{active: _vm.$route.path ==='/userpublish' || _vm.$route.path ==='/releasetask'},attrs:{"href":"#/userpublish"}},[_vm._v("我发布的任务")])]),_c('dd',[_c('a',{class:{active: _vm.$route.path ==='/userjoin'},attrs:{"href":"#/userjoin"}},[_vm._v("我参与的任务")])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('dt',[_c('a',{attrs:{"href":"javascript:void(0)"}},[_vm._v("个人信息")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('dt',[_c('a',{attrs:{"href":"javascript:void(0)"}},[_vm._v("交易中心")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('dt',[_c('a',{attrs:{"href":"javascript:void(0)"}},[_vm._v("我的资产")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('dt',[_c('a',{attrs:{"href":"javascript:void(0)"}},[_vm._v("身份认证")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('dt',[_c('a',{attrs:{"href":"javascript:void(0)"}},[_vm._v("我的收藏")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('dt',[_c('a',{attrs:{"href":"javascript:void(0)"}},[_vm._v("任务大厅")])])
}]

export { render, staticRenderFns }