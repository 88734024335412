<template>
  <div class="footer">
    <div class="container footer_nav">
      <div class="footer_top" >
        <div class="footer_item">
          <ul>
            <li v-if="onesort.length">{{onesort[0].name}}</li>
            <li @click="$router.push(item.path)" v-for="item in onedata" :key="item.id">{{item.title}}</li>
          </ul>
        </div>
        <div class="footer_item">
          <ul>
            <li v-if="onesort.length">{{onesort[1].name}}</li>
            <li @click="$router.push(item.path)" v-for="item in twodata" :key="item.id">{{item.title}}</li>
          </ul>
        </div>
        <div class="footer_item">
          <ul>
            <li v-if="onesort.length">{{onesort[2].name}}</li>
           <li @click="$router.push(item.path)" v-for="item in threedata" :key="item.id">{{item.title}}</li>
          </ul>
        </div>
        <div class="footer_item">
          <ul>
            <li v-if="onesort.length">{{onesort[3].name}}</li>
            <li @click="$router.push(item.path)"  v-for="item in fourdata" :key="item.id">{{item.title}}</li>
          </ul>
        </div>
        <img src="../assets/image/erweima.png" alt="">
      </div>
      <div class="footer_bot">
        <p>友情链接</p>
        <ul>
          <li>投诉建议</li>
          <li>投诉建议</li>
          <li>投诉建议</li>
        </ul>
      </div>
      <a href="https://beian.miit.gov.cn/?vt=4&pos=108&his=0#/Integrated/index" target="_blank"><p>@2023 IT一条龙 浙ICP备18046171号-2</p></a>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      onesort: [], // 一级分类
      onedata: [],
      twodata: [],
      threedata: [],
      fourdata: []
    }
  },
  created () {
    this.getOneSort()
    this.getarticle()
  },
  methods: {
    // 获取文章的一级分类
    async getOneSort () {
      // 先从vuex中获取
      if (this.$store.state.article.onesort.length) {
        this.onesort = this.$store.state.article.onesort
      } else {
        await this.$store.dispatch('article/getonesort')
        this.onesort = this.$store.state.article.onesort
      }
      // console.log(this.onesort)
    },
    // 获取文章的标题
    async getarticle () {
      // 先从vuex中获取
      if (this.$store.state.article.article.length) {
        this.article = this.$store.state.article.article
      } else {
        await this.$store.dispatch('article/getarticle')
        this.article = this.$store.state.article.article
      }
      this.article.forEach((item, index) => {
        // 关于我们
        if (index === 0) {
          item.url = '#/about'
          item.path = '/about'
        }
        // 联系我们
        if (index === 1) {
          item.url = '#/contactus'
          item.path = '/contactus'
        }
        // 用户协议
        if (index === 2) {
          item.url = '#/useragree'
          item.path = '/useragree'
        }
        // 隐私协议
        if (index === 3) {
          item.url = '#/comsuggest'
          item.path = '/comsuggest'
        }
        // 投诉建议
        if (index === 4) {
          item.url = '#/privacyagree'
          item.path = '/privacyagree'
        }
        // 快速投诉
        if (index === 5) {
          item.url = '#/fraud'
          item.path = '/fraud'
        }
        // 发货方式
        if (index === 6) {
          item.url = '#/delivery'
          item.path = '/delivery'
        }
        // 支付方式
        if (index === 7) {
          item.url = '#/payway'
          item.path = '/payway'
        }
        // 如何购买
        if (index === 8) {
          item.url = '#/howbuy'
          item.path = '/howbuy'
        }
        // 如何注册
        if (index === 9) {
          item.url = '#/howregister'
          item.path = '/howregister'
        }
        // 开通店铺
        if (index === 10) {
          item.url = '#/liberalshop'
          item.path = '/liberalshop'
        }
        // 店铺费用
        if (index === 11) {
          item.url = '#/shopmoney'
          item.path = '/shopmoney'
        }
        // 发布商品
        if (index === 12) {
          item.url = '#/pushpro'
          item.path = '/pushpro'
        }
        // 商品排名
        if (index === 13) {
          item.url = '#/proorder'
          item.path = '/proorder'
        }
        // 防骗指南
        if (index === 14) {
          item.url = '#/nocheat'
          item.path = '/nocheat'
        }
        // 商品保障
        if (index === 15) {
          item.url = '#/prosecure'
          item.path = '/prosecure'
        }
        // 交易周期
        if (index === 16) {
          item.url = '#/buyweek'
          item.path = '/buyweek'
        }
        // 消费保障
        if (index === 17) {
          item.url = '#/buysecure'
          item.path = '/buysecure'
        }
      })

      this.onedata = this.article.slice(0, 5)
      this.twodata = this.article.slice(5, 10)
      this.threedata = this.article.slice(10, 14)
      this.fourdata = this.article.slice(14, this.article.length)
      // console.log(this.threedata)
    }
  }

}
</script>

<style lang = "less" scoped>
.footer{
  margin-top: 30px;
  background: #32373E;
  /* background-color: red; */
  .footer_nav{
    height: 450px;
    .footer_top{
      margin:0 56px 0 79px;
      padding-top: 53px;
      display: flex;
      height: 184px;
      border-bottom: 1px solid #F5F5F5;
      padding-bottom: 28px;
      .footer_item{
        margin-right: 139px;
      ul{
        li{
          cursor: pointer;
          width:60px;
          font-size: 13px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 400;
          color: #8E8E90;
          margin: 12px 12px;
          &:first-child{
            margin: 0;
            font-size: 15px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #FFFFFF;
          }
        }
      }
      }
      img{
        width: 125px;
        height: 125px;
      }
    }
    .footer_bot{
      margin:0 56px 0 79px;
      border-bottom: 1px solid #F5F5F5;
      padding-bottom: 28px;
      p{
        text-align: left;
        margin-top: 24px;
        margin-bottom: 19px;
        font-size: 15px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
      }
      ul{
        display: flex;
        li{
          margin-right: 29px;
          font-size: 13px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 400;
          color: #8E8E90;
        }
      }
    }
    p{
      text-align: center;
      font-size: 13px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 400;
      color: #8E8E90;
      margin-top: 24px;
    }
  }
}
</style>
