import axios from 'axios'
import { Message } from 'element-ui'
import store from '@/store'
import router from '@/router'
// 创建axios实例
const request = axios.create({
  baseURL: 'https://api.ityitiaolong.com'
  // timeout: 5000
})

// 添加请求拦截器
request.interceptors.request.use(function (config) {
  // 在发送请求之前做些什么
  // 在发送请求之前携带token
  if (store.state.user.token) {
    config.headers.Authorization = `JWT ${store.state.user.token}`
  }
  return config
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error)
})

// 添加响应拦截器
request.interceptors.response.use(function (response) {
  // 对响应数据做点什么
  return response
}, function (error) {
  // 对响应错误做点什么

  if (error.response) {
    if (error.response.status === 401) {
      // 1.提示
      Message.warning('身份认证超时,请重新登录')
      // 2.退出
      store.dispatch('user/logout')
      // 3. 跳转到登录页面
      router.push('/passlogin')
      return
    }
    if (error.response.status === 500) {
      Message.error('请联系管理员')
      return
    }
    if (error.response.data) {
      Message.error(error.response.data.message)
    }
  }
  return Promise.reject(error)
})

export default request
