import { GetBanner } from '@/api/banner'
export default {
  namespaced: true,
  state: {
    banners: []
  },
  mutations: {
    // 获取轮播图
    setbanners (state, item) {
      state.banners = item
    }
  },
  actions: {
    // 获取轮播图
    async  getBanner ({ commit }) {
      const res = await GetBanner()
      // console.log(res.data)
      commit('setbanners', res.data)
    }
  }
}
