import { GetShop, GetShopdetail } from '@/api/shop'
export default {
  namespaced: true,
  state: {
    shopItem: [] // 店铺信息
  },
  mutations: {
    // 获取店铺信息
    setshopItem (state, item) {
      state.shopItem = item
    }
  },
  actions: {
    // 获取店铺列表信息
    async  getshop ({ commit }) {
      const res = await GetShop()
      // console.log(res.data.results)
      commit('setshopItem', res.data.results)
    },
    // 获取店铺的详情数据
    async getshopdetail ({ commit }, id) {
      const res = await GetShopdetail(id)
      return res.data
    }
  }
}
