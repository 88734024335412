<template>
  <div>
    <div class="left" v-if="onesort.length !== 0">
         <dl>
            <dt  @click="pointdown1">
              <span>{{onesort[0].name}}</span>
              <i  v-if="down1" class="iconfont icon-shangjiantou-copy"></i>
              <i  v-else class="iconfont icon-xiajiantou"></i>
            </dt>
            <dd v-show="down1">
              <a v-for="item in onedata" :key="item.id" :href="item.url" :class="{active:$route.path === item.path}">{{item.title}}</a>
              <!-- <a href="#/contactus" :class="{active:$route.path === '/contactus'}">联系我们</a>
              <a href="#/useragree" :class="{active:$route.path === '/useragree'}">用户协议</a>
              <a href="#/privacyagree" :class="{active:$route.path === '/privacyagree'}">隐私协议</a>
              <a href="#/comsuggest" :class="{active:$route.path === '/comsuggest'}">投诉建议</a> -->
            </dd>
         </dl>
         <dl>
            <dt  @click="pointdown2">
              <span>{{onesort[1].name}}</span>
              <i  v-if="down2" class="iconfont icon-shangjiantou-copy"></i>
              <i  v-else class="iconfont icon-xiajiantou"></i>
            </dt>
            <dd v-show="down2">
              <a v-for="item in twodata" :key="item.id" :href="item.url" :class="{active:$route.path === item.path}">{{item.title}}</a>
              <!-- <a href="javascript:void(0)">快速投诉</a>
              <a href="javascript:void(0)">发货方式</a>
              <a href="javascript:void(0)">支付方式</a>
              <a href="javascript:void(0)">如何购买</a>
              <a href="javascript:void(0)">如何注册</a> -->
            </dd>
         </dl>
         <dl>
            <dt @click="pointdown3">
              <span>{{onesort[2].name}}</span>
              <i  v-if="down3" class="iconfont icon-shangjiantou-copy"></i>
              <i   v-else class="iconfont icon-xiajiantou"></i>
            </dt>
            <dd v-show="down3">
              <a v-for="item in threedata" :key="item.id" :href="item.url" :class="{active:$route.path === item.path}">{{item.title}}</a>
              <!-- <a href="javascript:void(0)">开通店铺</a>
              <a href="javascript:void(0)">店铺费用</a>
              <a href="javascript:void(0)">发布商品</a>
              <a href="javascript:void(0)">商品排名</a> -->
            </dd>
         </dl>
         <dl>
            <dt @click="pointdown4">
              <span>{{onesort[3].name}}</span>
              <i  v-if="down4" class="iconfont icon-shangjiantou-copy"></i>
              <i   v-else class="iconfont icon-xiajiantou"></i>
            </dt>
            <dd v-show="down4">
               <a v-for="item in fourdata" :key="item.id" :href="item.url" :class="{active:$route.path === item.path}">{{item.title}}</a>
              <!-- <a href="javascript:void(0)">防骗指南</a>
              <a href="javascript:void(0)">商品保障</a>
              <a href="javascript:void(0)">交易周期</a>
              <a href="javascript:void(0)">消费保障</a> -->
            </dd>
         </dl>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      down1: false,
      down2: false,
      down3: false,
      down4: false,
      onesort: [], // 一级分类数据
      article: [], // 文章标题和数据
      onedata: [],
      twodata: [],
      threedata: [],
      fourdata: []
    }
  },
  async  created () {
    await this.getOneSort()
    await this.getarticle()
    this.arttoFather()
    this.onedata.forEach(item => {
      if (item.path === this.$route.path) {
        this.down1 = true
      }
    })
    this.twodata.forEach(item => {
      if (item.path === this.$route.path) {
        this.down2 = true
      }
    })
    this.threedata.forEach(item => {
      if (item.path === this.$route.path) {
        this.down3 = true
      }
    })
    this.fourdata.forEach(item => {
      if (item.path === this.$route.path) {
        this.down4 = true
      }
    })
  },
  methods: {
    // 点击第一个 展开
    pointdown1 () {
      if (!this.down1) {
        this.down1 = true
        this.down2 = false
        this.down3 = false
        this.down4 = false
      } else {
        this.down1 = false
      }
    },
    pointdown2 () {
      if (!this.down2) {
        this.down1 = false
        this.down2 = true
        this.down3 = false
        this.down4 = false
      } else {
        this.down2 = false
      }
    },
    pointdown3 () {
      if (!this.down3) {
        this.down1 = false
        this.down2 = false
        this.down3 = true
        this.down4 = false
      } else {
        this.down3 = false
      }
    },
    pointdown4 () {
      if (!this.down4) {
        this.down1 = false
        this.down2 = false
        this.down3 = false
        this.down4 = true
      } else {
        this.down4 = false
      }
    },
    // 获取文章的一级分类
    async getOneSort () {
      // 先从vuex中获取
      if (this.$store.state.article.onesort.length) {
        this.onesort = this.$store.state.article.onesort
      } else {
        await this.$store.dispatch('article/getonesort')
        this.onesort = this.$store.state.article.onesort
      }
      // console.log(this.onesort)
    },
    // 获取文章的标题和数据
    async getarticle () {
      // 先从vuex中获取
      if (this.$store.state.article.article.length) {
        this.article = this.$store.state.article.article
      } else {
        await this.$store.dispatch('article/getarticle')
        this.article = this.$store.state.article.article
      }
      this.article.forEach((item, index) => {
        // 关于我们
        if (index === 0) {
          item.url = '#/about'
          item.path = '/about'
        }
        // 联系我们
        if (index === 1) {
          item.url = '#/contactus'
          item.path = '/contactus'
        }
        // 用户协议
        if (index === 2) {
          item.url = '#/useragree'
          item.path = '/useragree'
        }
        // 隐私协议
        if (index === 3) {
          item.url = '#/comsuggest'
          item.path = '/comsuggest'
        }
        // 投诉建议
        if (index === 4) {
          item.url = '#/privacyagree'
          item.path = '/privacyagree'
        }
        // 快速投诉
        if (index === 5) {
          item.url = '#/fraud'
          item.path = '/fraud'
        }
        // 发货方式
        if (index === 6) {
          item.url = '#/delivery'
          item.path = '/delivery'
        }
        // 支付方式
        if (index === 7) {
          item.url = '#/payway'
          item.path = '/payway'
        }
        // 如何购买
        if (index === 8) {
          item.url = '#/howbuy'
          item.path = '/howbuy'
        }
        // 如何注册
        if (index === 9) {
          item.url = '#/howregister'
          item.path = '/howregister'
        }
        // 开通店铺
        if (index === 10) {
          item.url = '#/liberalshop'
          item.path = '/liberalshop'
        }
        // 店铺费用
        if (index === 11) {
          item.url = '#/shopmoney'
          item.path = '/shopmoney'
        }
        // 发布商品
        if (index === 12) {
          item.url = '#/pushpro'
          item.path = '/pushpro'
        }
        // 商品排名
        if (index === 13) {
          item.url = '#/proorder'
          item.path = '/proorder'
        }
        // 防骗指南
        if (index === 14) {
          item.url = '#/nocheat'
          item.path = '/nocheat'
        }
        // 商品保障
        if (index === 15) {
          item.url = '#/prosecure'
          item.path = '/prosecure'
        }
        // 交易周期
        if (index === 16) {
          item.url = '#/buyweek'
          item.path = '/buyweek'
        }
        // 消费保障
        if (index === 17) {
          item.url = '#/buysecure'
          item.path = '/buysecure'
        }
      })

      this.onedata = this.article.slice(0, 5)
      this.twodata = this.article.slice(5, 10)
      this.threedata = this.article.slice(10, 14)
      this.fourdata = this.article.slice(14, this.article.length)
      // console.log(this.threedata)
    },
    // 把数据传给父组件
    arttoFather () {
      this.$emit('getArticle', this.article)
    }
  }
}
</script>

<style lang="less" scoped>
.left{
  margin: 0 0 200px 0;
  width: 230px;
  background-color: #fff;
  border: 1px solid #D8D8D8;
  border-top: 0;
  dl{
    cursor: pointer;
    dt{
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: 15px;
      height: 48px;
      border-top: 1px solid #D8D8D8;
      span{
        display: block;
        font-size: 15px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #000000;
      }
      i{
        display: block;
        font-size: 22px;
        margin-right: 15px;
      }
    }
    dd{
        a{
          position: relative;
          padding-left: 25px;
          display: block;
          height: 48px;
          line-height: 48px;
          font-size: 14px;
          border-top: 1px solid #D8D8D8;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #7A7A7A;
          &:hover{
            color: @base-color;
            background-color: #e5f1ee;
            &::before {
              position: absolute;
              content: '';
              width: 4px;
              height: 48px;
              background: @base-color;
              left: 0;
              top: 0;
            }
          }
        }
    }
  }
}
 //左侧切换样式
.active{
  color: @base-color !important;
  background-color: #e5f1ee;
  &::before {
    position: absolute;
    content: '';
    width: 4px;
    height: 48px;
    background: @base-color;
    left: 0;
    top: 0;
}
}
</style>
