import { GetClasslist } from '@/api/commin'
export default {
  namespaced: true,
  state: {
    classList: {}
  },
  mutations: {
    // 获取目录结构
    setclassList (state, item) {
      state.classList = item
    }
  },
  actions: {
    // 获取目录列表
    async  getClassList ({ commit }) {
      const res = await GetClasslist()
      // console.log(res.data.data, 88)
      commit('setclassList', res.data.data)
    }
  }
}
