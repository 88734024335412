<template>
<div>
  <div class="content container">
    <div class="content_bot">
        <ul>
          <li @click="$router.push({path:'/goodsdetail',query:{id:item1.id}})" v-for="(item1,index1) in item.slice(0,10)" :key="index1">
            <img :src ="item1.img" alt="">
            <div class="goods_item">
              <p>{{item1.price}}</p>
              <h5>{{item1.title}}</h5>
              <div class="goods_start">
                <div class="start_left">
                  <img :src ="item1.logo" alt="" />
                  <span >{{item1.name}}</span>
                </div>
                <div class="shop_style">
                  <dl>
                    <!-- v-if="JSON.parse(item.tags.biao)" -->
                    <dt v-if="JSON.parse(item1.marks.biao)"><a href="javascript:void(0)"><img src="../assets/image/shop_tab6.png" alt=""></a></dt>
                    <dt v-if="JSON.parse(item1.marks.zheng)"><a href="javascript:void(0)"><img src="../assets/image/shop_tab5.png" alt=""></a></dt>
                    <dt v-if="JSON.parse(item1.marks.zi)"><a href="javascript:void(0)"><img src="../assets/image/shop_tab4.png" alt=""></a></dt>
                    <dt v-if="JSON.parse(item1.marks.shou)"><a href="javascript:void(0)"><img src="../assets/image/shop_tab3.png" alt=""></a></dt>
                    <dt v-if="JSON.parse(item1.marks.an)"><a href="javascript:void(0)"><img src="../assets/image/shop_tab2.png" alt=""></a></dt>
                    <dt v-if="JSON.parse(item1.marks.bao)"><a href="javascript:void(0)"><img src="../assets/image/shop_tab1.png" alt=""></a></dt>
                    <dt v-if="JSON.parse(item1.marks.qi)"><a href="javascript:void(0)"><img src="../assets/image/shop_tab.png" alt=""></a></dt>
                  </dl>
                </div>
              </div>
            </div>
          </li>
        </ul>
    </div>
  </div>
</div>

</template>

<script>
export default {
  props: {
    item: {
      type: Array,
      required: true
    }

  },
  created () {
    // console.log(JSON.parse(this.item.tags.zi), 888)
  }
}
</script>

<style lang="less" scoped>
.content{
   .content_bot{
    ul{
      display: flex;
      flex-wrap: wrap;
      li{
        cursor: pointer;
        margin-right: 22.5px;
        margin-bottom: 22.5px;
        width: 222px;
        // height: 360px;
        background: #fff;
        &:nth-child(5n){
        margin-right: 0;
        }
        img{
        width: 222px;
        height: 222px;
        }
        .goods_item{
          padding: 15px 9px;
          p{
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 600;
            color: #FF0000;
          }
          h5{
            height: 40px; //固定高 防止标题内容不足一行 ，布局错乱
            margin-top: 9px;
            margin-bottom: 11px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 600;
            color: #000000;
            overflow: hidden;
            text-overflow:ellipsis;
            white-space: wrap;
            display: -webkit-box;
            -webkit-line-clamp: 2; //超出两行省略
            -webkit-box-orient: vertical;
          }
          .goods_start {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .start_left {
    img {
      width: 19.6px;
      height: 19.6px;
      margin-right: 4px;
    }
    span {
      font-size: 13px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #999999;
    }
  }
  .shop_style {
 dl {
    margin-top: 3px;
    display: flex;
    dt {
      img{
        margin-left: 3px;
        width: 15px;
        height: 15px;
        object-fit: cover;
      }
    }
  }
}
}
        }
      }
    }
  }
}

</style>
