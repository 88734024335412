import Vue from 'vue'
import Vuex from 'vuex'
import commin from '@/store/modules/commin.js'
import banner from '@/store/modules/banner.js'
import product from '@/store/modules/product.js'
import article from '@/store/modules/article.js'
import shop from '@/store/modules/shop.js'
import user from '@/store/modules/user.js'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    commin,
    banner,
    product,
    article,
    shop,
    user
  }
})
