// 引入封装的组件
import Vue from 'vue'
import HeadNav from '../components/HeadNav.vue'
import FooterNav from '../components/FooterNav.vue'
import UserNav from '../components/UserNav.vue'
import UserItem from '../components/UserItem.vue'
import ShopItem from '../components/ShopItem.vue'
import UserLeftNav from '../components/UserLeftNav.vue'
import NoItem from '../components/NoItem.vue'
import ServeCenterNav from '../components/ServeCenterNav.vue'
import MerchantHead from '../components/MerchantHead.vue'
import MerchantLeftNav from '../components/MerchantLeftNav.vue'

Vue.component('HeadNav', HeadNav)
Vue.component('FooterNav', FooterNav)
Vue.component('UserNav', UserNav)
Vue.component('UserItem', UserItem)
Vue.component('ShopItem', ShopItem)
Vue.component('UserLeftNav', UserLeftNav)
Vue.component('NoItem', NoItem)
Vue.component('ServeCenterNav', ServeCenterNav)
Vue.component('MerchantHead', MerchantHead)
Vue.component('MerchantLeftNav', MerchantLeftNav)
