
// 按需引入 elementui

import Vue from 'vue'
// import 'element-ui/lib/theme-chalk/index.css'
import showMessage from '@/utils/message'
import { CheckboxGroup, Select, Option, Checkbox, MessageBox, Col, Button, Tabs, TabPane, Form, FormItem, Input, Radio, RadioGroup, Upload, CarouselItem, Carousel, Steps, Step, Table, TableColumn } from 'element-ui'
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(Upload)
Vue.use(CarouselItem)
Vue.use(Carousel)
Vue.use(Steps)
Vue.use(Step)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Button)
Vue.use(Col)
Vue.use(Checkbox)
Vue.use(Select)
Vue.use(Option)
Vue.use(CheckboxGroup)
// Vue.use(MessageBox) //弹框不需要use 不然会额外在触发一层弹窗

Vue.prototype.$alert = MessageBox.alert
Vue.prototype.$message = showMessage
Vue.prototype.$confirm = MessageBox.confirm
