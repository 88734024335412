<template>
  <div>
    <div class="balance">
      <div class="balance_mon">
        <img src="../assets/image/wuqian.png" alt="">
        <p style="color:#D4D4D4;">暂无记录</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
      .balance{
        padding: 40px 0;
        display: flex;
        justify-content: center;
        background-color: #fff;
        .balance_mon{
          height: 140px;
          width: 140px;
          // background-color: red;
          display: flex;
          flex-direction: column;
          align-items: center;
          p{
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #7A7A7A;
          }
        }
      }
</style>
