import { render, staticRenderFns } from "./ServeCenterNav.vue?vue&type=template&id=7a22736e&scoped=true"
import script from "./ServeCenterNav.vue?vue&type=script&lang=js"
export * from "./ServeCenterNav.vue?vue&type=script&lang=js"
import style0 from "./ServeCenterNav.vue?vue&type=style&index=0&id=7a22736e&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a22736e",
  null
  
)

export default component.exports