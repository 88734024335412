<template>
<!--  商家后台头部 -->
 <div class="mer_all">
     <div class="merchant_top">
    <div class="left" @click="$router.push('/merchant')">
      <img src="../assets/image/merchantlogo.png" alt="">
      <p>商家后台</p>
    </div>
    <div class="right">
      <i class="iconfont icon-ren"></i>
      <p>东哥</p>
      <i class="iconfont icon-xiajiantou"></i>
      <div class="back" @click="$router.push('/index')">
      <a href="javascript:void(0)">返回平台首页</a>
      </div>
    </div>
  </div>
 </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.mer_all{
  width: 100%;
position: fixed;
left: 0;
top: 0;
z-index: 999;
  .merchant_top{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 55px;
  background-color: #fff;
  padding: 0 35px 0 20px;
  border-bottom: 1px solid #D9D8D8;
  .left{
    cursor: pointer;
    display: flex;
    img{
      width: 30px;
      height: 30px;
    }
    p{
      margin-left: 15px;
      letter-spacing: 1px;
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #000000;
    }
  }
  .right{
    height: 56px;
    cursor: pointer;
    display: flex;
    align-items: center;
    p{
      letter-spacing: 1px;
      margin: 0 7px;
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #000000;
    }
    .back{
    display: none;
    position: absolute;
    right: 0;
    top: 56px;
    width: 155px;
    height: 50px;
    background-color: #fff;
    box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
    border-radius: 3px;
    font-size: 14px;
    text-align: center;
    line-height: 50px;
  }
    &:hover{
      .back{
        display: block;
      }
    }
  }

}
}

</style>
