import { GetAllProduct, GetselProduct, GetProdetail } from '@/api/product'
export default {
  namespaced: true,
  state: {
    products: [] // 全部的数据

  },
  mutations: {
    // 获取商品列表
    setproducts (state, item) {
      state.products = item
    }

  },
  actions: {
    // 获取商品列表
    async  GetAllProduct ({ commit }, item) {
      const res = await GetAllProduct(item)
      // console.log(res.data.results)
      commit('setproducts', res.data.results)
    },
    async  getselProduct ({ commit }, item) {
      const res = await GetselProduct(item)
      return res.data.results
    },
    // 获取商品详情的数据
    async getprodetail ({ commit }, id) {
      const res = await GetProdetail(id)
      return res.data
    }
  }
}
